import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PosPage from "./PosPage";
import ConfigPage from "./ConfigPage";

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
    console.log(window.location.pathname)
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<PosPage />} index />
            <Route path="/config" element={<ConfigPage />} />
        </Routes>
    </BrowserRouter>
}
root.render(<App />);