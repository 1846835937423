import React, { useState } from 'react';

const products = [
    { id: 1, name: 'Product 1', price: 100000 },
    { id: 2, name: 'Product 2', price: 150000 },
    { id: 3, name: 'Product 3', price: 200000 },
    { id: 4, name: 'Product 4', price: 250000 },
    { id: 5, name: 'Product 5', price: 300000 },
    { id: 6, name: 'Product 6', price: 350000 },
    { id: 7, name: 'Product 7', price: 50000 },
    { id: 8, name: 'Product 8', price: 100000 },
    { id: 9, name: 'Product 9', price: 300000 },
    { id: 10, name: 'Product 10', price: 60000 },
    { id: 11, name: 'Product 11', price: 140000 }
];

function PosPage() {
    const [cart, setCart] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [invoiceId, setInvoiceId] = useState(null);
    const [consumer, setConsumer] = useState({
        code: "",
        name: "",
        address: "",
        tax_code: "",
        citizen_identification: "",
        phone: "",
        buyer_name: "",
        bank_account: "",
    });
    const [paymentMethod, setPaymentMethod] = useState("CASH");
    const [recipients, setRecipients] = useState();
    const [needToSendMail, setNeedToSendMail] = useState(false);
    const [submitToTax, setSubmitToTax] = useState(true);

    const currencyFormatter = new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
    });

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleRecipientsChange = (e) => setRecipients(e.target.value);

    const handleNeedToSendMailChange = (e) => setNeedToSendMail(e.target.checked);

    const handleSubmitToTaxChange = (e) => setSubmitToTax(e.target.checked);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setConsumer((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const addToCart = (product) => {
        const existingProduct = cart.find((item) => item.id === product.id);
        if (existingProduct) {
            setCart(
                cart.map((item) =>
                    item.id === product.id
                        ? { ...item, quantity: item.quantity + 1 }
                        : item
                )
            );
        } else {
            setCart([...cart, { ...product, quantity: 1 }]);
        }
    };

    const removeFromCart = (id) => {
        setCart(cart.filter((item) => item.id !== id));
    };

    const updateQuantity = (id, quantity) => {
        if (quantity < 1) return;
        setCart(
            cart.map((item) =>
                item.id === id ? { ...item, quantity: quantity } : item
            )
        );
    };

    const calculateAmount = () => {
        return cart.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    const calculateTotal = () => {
        return calculateAmount() + calculateTotalTax();
    };

    const calculateTotalTax = () => {
        return calculateAmount() * 0.05;
    };

    const handlePayment = async () => {
        setIsLoading(true); // Show loading

        const invoice_lines = cart.map((item) => ({
            code: item.id,
            name: item.name,
            unit: "cai",
            price: item.price,
            quantity: item.quantity,
            vat_rate: "FIVE",
            discount_amount: 0,
            override_flag: false,
            promoted: false,
        }));

        const invoiceData = {
            consumer: consumer,
            invoice_lines: invoice_lines,
            status: "ORIGINAL",
            has_tax_authorities: true,
            payment_method: paymentMethod,
        };

        const data = {
            invoice_data: invoiceData,
            async: true,
            submit_to_tax_request: {
                recipients: [recipients],
                need_to_send_mail: needToSendMail,
                submit_to_tax: submitToTax,
            },
        };

        try {
            const response = await fetch('/api/app-demo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Payment successful:', result);
                alert("Create invoice successful");
                setInvoiceId(result.request_id);
                setIsLoading(false);
            } else {
                console.error('Payment failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    const getProcessData = async () => {
        const exportResponse = await fetch(`/api/app-demo/${invoiceId}/get-process-data`, {
            method: 'GET',
        });
        if (exportResponse.ok) {
            const result = await exportResponse.json();
            return result;
        } else {
            console.error('Failed to get process data :', exportResponse.statusText);
            return null;
        }
    }

    const loadPdf = async () => {
        const data = await getProcessData();
        if (data.data != null) {
            // If payment successful, call API to export PDF using the returned ID
            const exportResponse = await fetch(`/api/app-demo/${data.data.id}/export`, {
                method: 'POST',
            });

            if (exportResponse.ok) {
                const result = await exportResponse.json();

                setPdfUrl(`https://docs.google.com/viewer?url=${result.download_url}&embedded=true`); // Store PDF URL to show later
            } else {
                console.error('Failed to export PDF:', exportResponse.statusText);
            }
        } else {
            alert(data);
        }

        setIsLoading(false);
    }

    return (
        <div className="container">
            <h1 className="my-4">POS App</h1>
            <div className="row">
                {/* Optionally, show PDF inline */}
                {pdfUrl != null ? (
                    <div className="col-md-5">
                        <iframe
                            src={pdfUrl}
                            title="Invoice PDF"
                            width="100%"
                            height="700px"
                            className="mt-4"
                        />
                    </div>
                ) : (
                    <div className="col-md-5">
                        <h2>Products</h2>
                        <div className="row">
                            {products.map((product) => (
                                <div className="col-md-4" key={product.id}>
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <h5 className="card-title">{product.name}</h5>
                                            <p className="card-text">
                                                {currencyFormatter.format(product.price)}
                                            </p>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => addToCart(product)}
                                            >
                                                Thêm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="col-md-4">
                    <h2>Cart</h2>
                    {cart.length === 0 ? (
                        <p>Your cart is empty</p>
                    ) : (
                        <ul className="list-group">
                            {cart.map((item) => (
                                <li key={item.id} className="list-group-item">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {item.name} x{' '}
                                            <input
                                                type="number"
                                                value={item.quantity}
                                                min="1"
                                                onChange={(e) =>
                                                    updateQuantity(item.id, parseInt(e.target.value))
                                                }
                                                className="form-control d-inline-block"
                                                style={{width: '60px'}}
                                            />
                                        </div>
                                        <span>{currencyFormatter.format(item.price * item.quantity)}</span>
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => removeFromCart(item.id)}
                                        >
                                            Xóa
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                    <h4 className="mt-4">Tax: {currencyFormatter.format(calculateTotalTax())}</h4>
                    <h3 className="mt-4">Total: {currencyFormatter.format(calculateTotal())}</h3>
                    <div className="d-flex justify-content-between mt-3">
                        {invoiceId == null ? (
                            <button
                                className="btn btn-success"
                                onClick={handlePayment}
                                disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Xuất hóa đơn'}
                            </button>
                        ) : (
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    setIsLoading(true);
                                    loadPdf();
                                }}
                                disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Xem hoá đơn'}
                            </button>
                        )}
                        <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                                setCart([]);
                                setInvoiceId(null);
                                setPdfUrl(null);
                                setNeedToSendMail(false);
                                setSubmitToTax(true);
                                setRecipients("");
                                setConsumer({code: "",
                                    name: "",
                                    address: "",
                                    tax_code: "",
                                    citizen_identification: "",
                                    phone: "",
                                    buyer_name: "",
                                    bank_account: "",
                                })
                            }}
                            disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Reset'}
                        </button>
                    </div>
                </div>
                <div className="col-md-3">
                    <h4>Thông tin người mua hàng</h4>
                    <div className="form-group">
                        <label>Mã khách hàng</label>
                        <input
                            type="text"
                            className="form-control"
                            name="code"
                            value={consumer.code}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Tên công ty</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={consumer.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Địa chỉ</label>
                        <input
                            type="text"
                            className="form-control"
                            name="address"
                            value={consumer.address}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Mã số thuế</label>
                        <input
                            type="text"
                            className="form-control"
                            name="tax_code"
                            value={consumer.tax_code}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>CMND/CCCD</label>
                        <input
                            type="text"
                            className="form-control"
                            name="citizen_identification"
                            value={consumer.citizen_identification}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Số điện thoại</label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone"
                            value={consumer.phone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Tên người mua</label>
                        <input
                            type="text"
                            className="form-control"
                            name="buyer_name"
                            value={consumer.buyer_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Số tài khoản ngân hàng</label>
                        <input
                            type="text"
                            className="form-control"
                            name="bank_account"
                            value={consumer.bank_account}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Hình thức thanh toán</label>
                        <select
                            className="form-control"
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                        >
                            <option value="CASH">Tiền mặt (TM)</option>
                            <option value="BANK_TRANSFER">Chuyển khoản (CK)</option>
                            <option value="UNKNOWN">Không xác định (TM/CK)</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Email người nhận hóa đơn</label>
                        <input
                            type="email"
                            className="form-control"
                            value={recipients}
                            onChange={handleRecipientsChange}
                        />
                    </div>
                    <div className="form-group form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={needToSendMail}
                            onChange={handleNeedToSendMailChange}
                        />
                        <label className="form-check-label">Gửi email cho khách hàng</label>
                    </div>
                    <div className="form-group form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={submitToTax}
                            onChange={handleSubmitToTaxChange}
                        />
                        <label className="form-check-label">Gửi cơ quan thuế</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PosPage;
