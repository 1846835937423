import React, { useState, useEffect } from 'react';

function ConfigPage() {
    const [tenantConnectionCode, setTenantConnectionCode] = useState('');
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [updatingConfig, setUpdatingConfig] = useState(false);

    useEffect(async () => {
        await fetchTemplates();
        const response = await fetch('/api/app-demo/config', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.ok) {
            const result = await response.json();
            setSelectedTemplateId(result.template_id);
            setTenantConnectionCode(result.tenant_connection_code);
        } else {
            console.error('Failed to fetch config:', response.statusText);
        }
    }, []);

    // Handle tenant connection code input change
    const handleTenantConnectionCodeChange = (e) => {
        setTenantConnectionCode(e.target.value);
        updateConfigTenant(e.target.value);
    };

    // Fetch templates based on tenant connection code
    const fetchTemplates = async () => {
        setLoadingTemplates(true);
        try {
            const response = await fetch(`/api/app-demo/templates`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                setTemplates(result.items);  // Set the templates array
            } else {
                alert('Failed to fetch templates: ' + response.statusText);
            }
        } catch (error) {
            console.error('Error occurred while fetching templates:', error);
        } finally {
            setLoadingTemplates(false);
        }
    };

    const updateConfigTemplate = () => {
        setUpdatingConfig(true);
        const data = JSON.stringify({ template_id: selectedTemplateId });
        updateConfig(data);
    };

    const updateConfigTenant = (tenantConnectionCode) => {
        setUpdatingConfig(true);
        const data = JSON.stringify({ tenant_connection_code: tenantConnectionCode });
        updateConfig(data);
    };

    const updateConfig = async (data) => {
        setUpdatingConfig(true);
        try {
            const response = await fetch('/api/app-demo/config', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: data,
            });

            if (response.ok) {
                alert('Config updated successfully');
                console.log('Config updated successfully');
            } else {
                alert('Failed to update config');
                console.error('Failed to update config:', response.statusText);
            }
        } catch (error) {
            alert('Error occurred while updating config');
            console.error('Error occurred while updating config:', error);
        } finally {
            setUpdatingConfig(false);
        }
    };

    return (
        <div className="container">
            <h1 className="my-4">Configuration Page</h1>

            {/* Tenant ID Input */}
            <div className="mb-3">
                <label htmlFor="tenant_connection_code" className="form-label">Tenant Connection Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="tenant_connection_code"
                    value={tenantConnectionCode}
                    onChange={handleTenantConnectionCodeChange}
                    placeholder="Enter Tenant ID"
                />
                <button
                    className="btn btn-primary mt-3"
                    onClick={fetchTemplates}
                    disabled={loadingTemplates || !tenantConnectionCode} // Disable button if loading or tenant connection code is empty
                >
                    {loadingTemplates ? 'Loading Templates...' : 'Fetch Templates'}
                </button>
            </div>

            {/* Template Selection Dropdown */}
            {templates.length > 0 && (
                <div className="mb-3">
                    <label htmlFor="templateSelect" className="form-label">Select a Template</label>
                    <select
                        className="form-select"
                        id="templateSelect"
                        value={selectedTemplateId}
                        onChange={(e) => setSelectedTemplateId(e.target.value)}
                    >
                        <option value="">Choose a Template</option>
                        {templates.map((template) => (
                            <option key={template.id} value={template.id}>
                                {template.name} ({template.symbol_classification})
                            </option>
                        ))}
                    </select>
                    <button
                        className="btn btn-success mt-3"
                        onClick={updateConfigTemplate}
                        disabled={updatingConfig || !selectedTemplateId} // Disable if no template is selected
                    >
                        {updatingConfig ? 'Updating Config...' : 'Update Config'}
                    </button>
                </div>
            )}
        </div>
    );
}

export default ConfigPage;
